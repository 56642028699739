import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import MenuIcon from '@mui/icons-material/Menu';
import SendIcon from '@mui/icons-material/Send';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Howl} from 'howler';
import {t} from 'i18next';
import update from 'immutability-helper';
import {isEmpty, uniq, values} from 'lodash';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {
  useConfiguration,
  useConfigurations,
} from '../../../hooks/configuration';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {AlarmModuleNodeListResponse} from '../../../interfaces/AlarmModuleNode';
import {AMSEModuleNodeListResponse} from '../../../interfaces/AMSEModuleNode';
import {BeltMonitoringNodeListResponse} from '../../../interfaces/BeltMonitoringNode';
import {BroadcastMessageListResponse} from '../../../interfaces/BroadcastMessage';
import {CommtracNodeListResponse} from '../../../interfaces/CommtracNode';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  EventListResponse,
  EventSummaryResent,
  EventSummaryResponse,
} from '../../../interfaces/Event';
import {GasMonitoringNodeListResponse} from '../../../interfaces/GasMonitoringNode';
import {NodeListResponse} from '../../../interfaces/Node';
import reduxActions from '../../../redux/actions';
import reduxSelectors from '../../../redux/selectors';
import {
  OpenedEntity,
  OpenedEntityMode,
  OpenedEntityType,
} from '../../../utils/connect-view-panel';
import {getMsFromS} from '../../../utils/datetime';
import {
  CONNECT_EVENT_SOUNDS,
  getCompanyProductStatusByType,
} from '../../../utils/events';
import {SOUNDS} from '../../../utils/sounds';
import {isPresent} from '../../../utils/type-guards';
import AlarmItemUpsert from '../../alarm-nodes/AlarmItemUpsert';
import AlarmSimpleAcknowledgePopup from '../../alarm-nodes/AlarmSimpleAcknowledgePopup';
import AssetHumanItemUpsert from '../../asset-human/AssetHumanItemUpsert';
import AssetHumanUnassignedList from '../../asset-human/AssetHumanUnassignedList';
import AssetHumanGroupChat from '../../asset-human-group/AssetHumanGroupChat';
import AssetMachineItemUpsert from '../../asset-machine/AssetMachineItemUpsert';
import AssetMachineUnassignedList from '../../asset-machine/AssetMachineUnassignedList';
import BroadcastMessageClearButton from '../../broadcast-message/buttons/BroadcastMessageClearButton';
import BroadcastMessageUpsertButton from '../../broadcast-message/buttons/BroadcastMessageUpsertButton';
import AccessControl from '../../common/AccessControl';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import {MapLatLangCoordinates} from '../../common/Map';
import ModalDraggable from '../../common/ModalDraggable';
import TruncatedText from '../../common/TruncantedText';
import CommunicationNodeItemUpsert from '../../communication-node/CommunicationNodeItemUpsert';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import WifiPointItemUpsert from '../../wifi-point/WifiPointItemUpsert';
import AMSEModuleItemUpsert from '../AMSEModule/AMSEModuleItemUpsert';
import AMSSensorItemAcknowledgeButton from '../AMSSensor/AMSSensorItemAcknowledgeButton';
import AMSSensorItemUpsert from '../AMSSensor/AMSSensorItemUpsert';
import BeltNodeItemAcknowledgeButton from '../BeltMonitoring/BeltNodeItemAcknowledgeButton';
import BeltNodeItemActivationModal from '../BeltMonitoring/BeltNodeItemActivationModal';
import BeltNodeItemUpsert from '../BeltMonitoring/BeltNodeItemUpsert';
import {DashboardHistoryReportType} from '../DashboardPanelItem';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import AlarmLogReport, {
  AlarmLogReportData,
  getAlarmLogReportData,
} from './AlarmLogReport';
import {
  BeltMonitoringReport,
  BeltMonitoringReportData,
  getBeltMonitoringReportData,
} from './BeltMonitoringReport';
import CommtracNodesReport, {
  CommtracNodesReportData,
  getCommtracNodesReportData,
} from './CommtracNodesReport';
import {ConnectViewStats} from './ConnectViewStats';
import {
  AMSEModulesReportData,
  EModulesReport,
  getAMSEModulesReportData,
} from './EModulesReport';
import EventsReport, {
  EventsReportData,
  getEventsReportData,
} from './EventsReport';
import GasMonitoringReport, {
  GasMonitoringReportData,
  getGasMonitoringReportData,
} from './GasMonitoringReport';
import NodesReport, {getNodesReportData, NodesReportData} from './NodesReport';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
  onOpenHistory?: (
    id: number | string,
    type: DashboardHistoryReportType
  ) => void;
}

interface ConnectViewData {
  viewType:
    | 'commtracNodes'
    | 'nodes'
    | 'events'
    | 'alarmLog'
    | 'gasMonitoring'
    | 'eModules'
    | 'beltMonitoring';
  commtracNodes: CommtracNodesReportData;
  nodes: NodesReportData;
  events: EventsReportData;
  alarmLogs: AlarmLogReportData;
  gasMonitoring: GasMonitoringReportData;
  eModules: AMSEModulesReportData;
  beltMonitoring: BeltMonitoringReportData;
}

const getConnectViewData = (): ConnectViewData => ({
  viewType: 'commtracNodes',
  commtracNodes: getCommtracNodesReportData(),
  nodes: getNodesReportData(),
  events: getEventsReportData(),
  alarmLogs: getAlarmLogReportData(),
  gasMonitoring: getGasMonitoringReportData(),
  eModules: getAMSEModulesReportData(),
  beltMonitoring: getBeltMonitoringReportData(),
});

// eslint-disable-next-line complexity
export const ConnectView = (props: Props) => {
  const isDarkMode = useSelector(reduxSelectors.app.getIsDarkMode);
  const reduxDispatch = useDispatch();
  const company = useAppSelector(({assets}) => assets.company);
  const me = useAppSelector(({app}) => app.me);
  const userRole = me?.type_id;

  /****************/
  /* panel config */
  /****************/

  const getCheckClientRole = (data: any) => {
    if (data.viewType === 'eModules' && userRole === 5) {
      return {
        ...data,
        viewType: 'gasMonitoring',
        beltMonitoring: getBeltMonitoringReportData(),
      };
    } else {
      return data;
    }
  };

  const [config, setConfig] = useState<ConnectViewData>(
    !isEmpty(props.value)
      ? (getCheckClientRole(props.value) as ConnectViewData)
      : getConnectViewData()
  );

  useEffect(() => {
    props.onUpdate?.({...config});
  }, [config]);

  const [fullTabs, setFullTabs] = useState(false);

  /*********/
  /* nodes */
  /*********/

  const [fetchedNodesData, setFetchedNodesData] = useState<NodeListResponse>();
  const [fetchedNodesErrors, setFetchedNodesErrors] = useState<string[]>([]);
  const [fetchedNodesInProgress, setFetchedNodesInProgress] = useState(false);

  const fetchNodes = useCallback(async () => {
    setFetchedNodesInProgress(true);
    setFetchedNodesErrors([]);
    try {
      const params = {
        ...config.nodes.params,
        standby: config.viewType !== 'nodes',
      };
      const resp = await API.get<NodeListResponse>(`${apiBaseUrl}/node`, {
        params,
      });
      if (config.viewType === 'nodes') {
        reduxActions.assets.setAssetNodes(
          reduxDispatch,
          resp.data?.items || []
        );
      }
      setFetchedNodesData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedNodesErrors(messages);
    }
    setFetchedNodesInProgress(false);
  }, [config.nodes.params, config.viewType]);

  useEffect(() => {
    fetchNodes();
  }, [config.nodes.params]);

  const nodesRefresh = useConfiguration(
    'auto-refresh',
    'commtrac_commnode_autorefresh_rate'
  );
  const nodesRefreshInterval = nodesRefresh?.value
    ? getMsFromS(+nodesRefresh?.value)
    : null;
  useRefreshInterval(fetchNodes, nodesRefreshInterval);

  /******************/
  /* commtrac nodes */
  /******************/

  const [fetchedCommtracNodesData, setFetchedCommtracNodesData] =
    useState<CommtracNodeListResponse>();
  const [fetchedCommtracNodesErrors, setFetchedCommtracNodesErrors] = useState<
    string[]
  >([]);
  const [fetchedCommtracNodesInProgress, setFetchedCommtracNodesInProgress] =
    useState(false);

  const fetchCommtracNodes = useCallback(async () => {
    setFetchedCommtracNodesInProgress(true);
    setFetchedCommtracNodesErrors([]);
    try {
      const params = {
        ...config.commtracNodes.params,
        standby: config.viewType !== 'commtracNodes',
      };
      const resp = await API.get<CommtracNodeListResponse>(
        `${apiBaseUrl}/commtrac-node`,
        {params}
      );
      setFetchedCommtracNodesData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedCommtracNodesErrors(messages);
    }
    setFetchedCommtracNodesInProgress(false);
  }, [config.viewType, config.commtracNodes.params]);

  useEffect(() => {
    fetchCommtracNodes();
  }, [config.commtracNodes.params]);

  const commtracNodeRefresh = useConfiguration(
    'auto-refresh',
    'commtrac_miner_autorefresh_rate'
  );
  const commtracNodeRefreshInterval = commtracNodeRefresh?.value
    ? getMsFromS(+commtracNodeRefresh?.value)
    : null;
  useRefreshInterval(fetchCommtracNodes, commtracNodeRefreshInterval);

  // Gas Monitoring Nodes
  const [openedAMSNode, setOpenedAMSNode] = useState<OpenedEntity>([]);
  const [fetchedGasMonitoringNodes, setFetchedGasMonitoringNodes] =
    useState<GasMonitoringNodeListResponse>();
  const [fetchedGasMonitoringNodesErrors, setFetchedGasMonitoringNodesErrors] =
    useState<string[]>([]);
  const [
    fetchedGasMonitoringNodesInProgress,
    setFetchedGasMonitoringNodesInProgress,
  ] = useState(false);

  const fetchGasMonitoringNodes = useCallback(async () => {
    setFetchedGasMonitoringNodesInProgress(true);
    setFetchedGasMonitoringNodesErrors([]);

    try {
      const params = config.gasMonitoring?.params
        ? {
            ...config.gasMonitoring.params,
            standby: config.viewType !== 'gasMonitoring',
          }
        : {
            standby: config.viewType !== 'gasMonitoring',
          };

      const resp = await API.get<GasMonitoringNodeListResponse>(
        `${apiBaseUrl}/ams`,
        {params}
      );

      setFetchedGasMonitoringNodes(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedGasMonitoringNodesErrors(messages);
    }
    setFetchedGasMonitoringNodesInProgress(false);
  }, [config.viewType, config.gasMonitoring?.params]);

  useEffect(() => {
    fetchGasMonitoringNodes();
  }, [config.gasMonitoring?.params]);

  const gasMonitoringNodeRefresh = useConfiguration(
    'auto-refresh',
    'ams_grid_autorefresh_rate'
  );
  const gasMonitoringNodeRefreshInterval = gasMonitoringNodeRefresh?.value
    ? getMsFromS(+gasMonitoringNodeRefresh.value)
    : null;
  useRefreshInterval(fetchGasMonitoringNodes, gasMonitoringNodeRefreshInterval);

  // AMS E-Modules Nodes
  const [openedAMSEModuleNode, setOpenedAMSEModuleNode] =
    useState<OpenedEntity>([]);
  const [fetchedAMSEModuleNodes, setFetchedAMSEModuleNodes] =
    useState<AMSEModuleNodeListResponse>();
  const [fetchedAMSEModuleNodesErrors, setFetchedAMSEModuleNodesErrors] =
    useState<string[]>([]);
  const [
    fetchedAMSEModuleNodesInProgress,
    setFetchedAMSEModuleNodesInProgress,
  ] = useState(false);

  const fetchAMSEModuleNodes = useCallback(async () => {
    setFetchedAMSEModuleNodesInProgress(true);
    setFetchedAMSEModuleNodesErrors([]);

    try {
      const params = config.eModules?.params ?? {standby: false};

      const resp = await API.get<AMSEModuleNodeListResponse>(
        `${apiBaseUrl}/ams/emodule`,
        {params}
      );

      setFetchedAMSEModuleNodes(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedAMSEModuleNodesErrors(messages);
    }
    setFetchedAMSEModuleNodesInProgress(false);
  }, [config.viewType, config.eModules?.params]);

  useEffect(() => {
    fetchAMSEModuleNodes();
  }, [config.eModules?.params]);

  // Here gasMonitoringNodeRefreshInterval used since there is no an appropriate value for EModules
  useRefreshInterval(fetchAMSEModuleNodes, gasMonitoringNodeRefreshInterval);

  // Belt Monitoring Nodes
  const [openedBeltMonitoringNode, setOpenedBeltMonitoringNode] =
    useState<OpenedEntity>([]);
  const [fetchedBeltMonitoringNodes, setFetchedBeltMonitoringNodes] =
    useState<BeltMonitoringNodeListResponse>();
  const [
    fetchedBeltMonitoringNodesErrors,
    setFetchedBeltMonitoringNodesErrors,
  ] = useState<string[]>([]);
  const [
    fetchedBeltMonitoringNodesInProgress,
    setFetchedBeltMonitoringNodesInProgress,
  ] = useState(false);

  const fetchBeltMonitoringNodes = useCallback(async () => {
    setFetchedBeltMonitoringNodesInProgress(true);
    setFetchedBeltMonitoringNodesErrors([]);
    await reduxActions.assets.fetchBeltNodes(reduxDispatch);

    try {
      const params = config.beltMonitoring?.params
        ? {
            ...config.beltMonitoring.params,
            standby: config.viewType !== 'beltMonitoring',
          }
        : {
            standby: config.viewType !== 'beltMonitoring',
          };

      const resp = await API.get<BeltMonitoringNodeListResponse>(
        `${apiBaseUrl}/belt`,
        {params}
      );
      setFetchedBeltMonitoringNodes(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedBeltMonitoringNodesErrors(messages);
    }
    setFetchedBeltMonitoringNodesInProgress(false);
  }, [config.viewType, config.beltMonitoring?.params]);

  useEffect(() => {
    fetchBeltMonitoringNodes();
  }, [config.beltMonitoring?.params]);

  const beltMonitoringNodeRefresh = useConfiguration(
    'auto-refresh',
    'belt_grid_refresh'
  );
  const beltMonitoringNodeRefreshInterval = beltMonitoringNodeRefresh?.value
    ? getMsFromS(+beltMonitoringNodeRefresh.value)
    : null;
  // Here beltMonitoringNodeRefreshInterval used since there is no an appropriate value for BeltMonitoringNodes
  useRefreshInterval(
    fetchBeltMonitoringNodes,
    beltMonitoringNodeRefreshInterval
  );

  /**********/
  /* events */
  /**********/

  const filteredEventTypeByProduct = (types: string[]) => {
    return types.filter((type) => {
      const productStatus = getCompanyProductStatusByType(
        parseInt(type)
      ).product;
      if (productStatus === '') {
        return true;
      } else if (productStatus === 'commtrac_enabled') {
        return company?.commtrac_enabled;
      } else if (productStatus === 'alarm_enabled') {
        return company?.alarm_enabled;
      } else if (productStatus === 'ams_enabled') {
        return company?.ams_enabled;
      } else if (productStatus === 'belt_enabled') {
        return company?.belt_enabled;
      } else if (productStatus === 'hazard_ai_enabled') {
        return company?.hazard_ai_enabled;
      }
    });
  };

  const [fetchedEventsData, setFetchedEventsData] =
    useState<EventListResponse>();
  const [fetchedEventsErrors, setFetchedEventsErrors] = useState<string[]>([]);
  const [fetchedEventsInProgress, setFetchedEventsInProgress] = useState(false);
  const [eventIds, setEventIds] = useState<
    {
      id: number;
      product: string;
      ack: '0' | '1' | null;
    }[]
  >([]);

  const fetchEvents = useCallback(async () => {
    setFetchedEventsInProgress(true);
    setFetchedEventsErrors([]);
    try {
      const params = {
        ...config.events.params,
        type: filteredEventTypeByProduct(config.events.params.type ?? []),
        limit: config.viewType !== 'events' ? 1 : config.events.params.limit,
      };
      const resp = await API.get<EventListResponse>(`${apiBaseUrl}/event`, {
        params,
      });
      setFetchedEventsData(resp.data);
      setEventIds(
        resp?.data?.items?.map((it) => ({
          id: it?.id,
          product: it?.product,
          ack: it?.ack,
        }))
      );
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedEventsErrors(messages);
    }
    setFetchedEventsInProgress(false);
  }, [config.viewType, config.events.params, company]);

  useEffect(() => {
    fetchEvents();
  }, [config.events.params]);

  const eventRefresh = useConfiguration(
    'auto-refresh',
    'event_summary_autorefresh_rate'
  );
  const eventRefreshInterval = eventRefresh?.value
    ? getMsFromS(+eventRefresh?.value)
    : null;
  useRefreshInterval(fetchEvents, eventRefreshInterval);
  const configurations = useConfigurations();
  const playedEventIds = useAppSelector(({app}) => app.playedEventIds);

  /*****************/
  /* event summary */
  /*****************/

  const [fetchedEventSummaryData, setFetchedEventSummaryData] =
    useState<EventSummaryResponse>();
  const [fetchedEventSummaryErrors, setFetchedEventSummaryErrors] = useState<
    string[]
  >([]);
  const [fetchedEventSummaryInProgress, setFetchedEventSummaryInProgress] =
    useState(false);

  const fetchEventSummary = useCallback(async () => {
    setFetchedEventSummaryInProgress(true);
    setFetchedEventSummaryErrors([]);
    try {
      const params = {
        date_start: config.events.params.date_start,
        date_end: config.events.params.date_end,
      };
      const resp = await API.get<EventSummaryResponse>(
        `${apiBaseUrl}/event/summary`,
        {params}
      );
      setFetchedEventSummaryData(resp.data);
      if (soundFiles.length === 0) {
        setSoundFiles(getSoundFilesFromEventSummary(resp.data));
      }
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedEventSummaryErrors(messages);
    }
    setFetchedEventSummaryInProgress(false);
  }, [
    config.events.params.date_start,
    config.events.params.date_end,
    configurations,
    playedEventIds,
  ]);

  const eventSummaryRefresh = useConfiguration(
    'commtrac',
    'commtrac_sound_interval'
  );
  const eventSummaryRefreshInterval = eventSummaryRefresh?.value
    ? getMsFromS(+eventSummaryRefresh?.value)
    : null;
  useRefreshInterval(fetchEventSummary, eventSummaryRefreshInterval);

  useEffect(() => {
    fetchEventSummary();
  }, []);

  useEffect(() => {
    fetchEventSummary();
  }, [config.events.params.date_start, config.events.params.date_end]);

  const newEventSoundConfiguration = useConfiguration(
    'commtrac',
    'sound_new_event'
  );
  const isCompactMode = useAppSelector(reduxSelectors.app.getIsCompactMode);

  const getSoundFilesFromEventSummary = (
    eventSummary: EventSummaryResponse
  ) => {
    const eventTypes = uniq(
      company?.ams_enabled
        ? eventSummary.connect.sound
            .map((i) => i.type)
            .concat(eventSummary.ams.sound.map((i) => i.type))
        : eventSummary.connect.sound.map((i) => i.type)
    );

    const soundNames = eventTypes
      .map((i) => configurations[CONNECT_EVENT_SOUNDS[i] ?? '']?.value)
      .filter(isPresent);

    const newEvents = eventSummary.connect.sound.filter(
      (i) => !playedEventIds.includes(i.id)
    );
    if (newEvents.length) {
      soundNames.unshift(newEventSoundConfiguration?.value ?? '');
      reduxActions.app.setApp(reduxDispatch, {
        playedEventIds: [...playedEventIds, ...newEvents.map((i) => i.id)],
      });
    }
    return uniq(soundNames.map((i) => SOUNDS[i]).filter(isPresent));
  };

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const muteSounds = useAppSelector(reduxSelectors.app.getSoundMuteMode);
  const [soundFiles, setSoundFiles] = useState<any[]>([]);
  const isSoundFilesEmpty = soundFiles.length === 0;
  const sound = useMemo(() => {
    const src = soundFiles[0];
    return src ? new Howl({src}) : null;
  }, [soundFiles]);

  useEffect(() => {
    if (sound) {
      if (muteSounds) {
        const message =
          'New event received, please enable sounds to miss nothing.';
        enqueueSnackbar(message, {
          variant: 'warning',
          action: (key) => (
            <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
          ),
        });
        return;
      }

      if (sound.state() === 'loaded') {
        sound.play();
      } else {
        sound.once('load', () => sound.play());
      }
      sound.once('end', () => {
        setSoundFiles(soundFiles.slice(1));
      });

      return () => {
        sound.unload();
      };
    }
  }, [sound]);

  useEffect(() => {
    if (!isCompactMode) {
      setFullTabs(true);
    } else {
      setFullTabs(false);
    }
  }, [isCompactMode]);

  /**********************/
  /* broadcast messages */
  /**********************/

  const [fetchedBroadcastMessagesData, setFetchedBroadcastMessagesData] =
    useState<BroadcastMessageListResponse>();
  const [fetchedBroadcastMessagesErrors, setFetchedBroadcastMessagesErrors] =
    useState<string[]>([]);

  const fetchBroadcastMessages = async () => {
    setFetchedBroadcastMessagesErrors([]);
    try {
      const params = {status: 1};
      const resp = await API.get<BroadcastMessageListResponse>(
        `${apiBaseUrl}/commtrac-broadcast-message`,
        {params}
      );
      setFetchedBroadcastMessagesData(resp.data);
      if (!resp.data.items.some((i) => i.id === selectedBroadcastMessageId)) {
        setSelectedBroadcastMessageId(resp.data.items[0]?.id);
      }
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedBroadcastMessagesErrors(messages);
    }
  };

  const broadcastMessagesRefresh = useConfiguration(
    'auto-refresh',
    'event_summary_autorefresh_rate'
  );
  const broadcastMessagesRefreshInterval = broadcastMessagesRefresh?.value
    ? getMsFromS(+broadcastMessagesRefresh?.value)
    : null;
  useRefreshInterval(fetchBroadcastMessages, broadcastMessagesRefreshInterval);

  useEffect(() => {
    fetchBroadcastMessages();
  }, []);

  const [selectedBroadcastMessageId, setSelectedBroadcastMessageId] =
    useState<number>();
  const selectedBroadcastMessage = fetchedBroadcastMessagesData?.items.find(
    (i) => i.id === selectedBroadcastMessageId
  );

  useEffect(() => {
    if (config.viewType === 'commtracNodes') {
      fetchCommtracNodes();
    } else if (config.viewType === 'nodes') {
      fetchNodes();
    } else if (config.viewType === 'events') {
      fetchEvents();
    } else if (config.viewType === 'alarmLog') {
      fetchAlarmLogs();
    } else if (config.viewType === 'gasMonitoring') {
      fetchGasMonitoringNodes();
    } else if (config.viewType === 'eModules') {
      fetchAMSEModuleNodes();
    } else if (config.viewType === 'beltMonitoring') {
      fetchBeltMonitoringNodes();
    }
  }, [config.viewType]);

  const [isUnassignedEmployeesOpened, setIsUnassignedEmployeesOpened] =
    useState(false);
  const [isUnassignedAssetsOpened, setIsUnassignedAssetsOpened] =
    useState(false);

  /**********************/
  /*       alarms       */
  /**********************/
  const [openedAlarmLogNode, setOpenedAlarmLogNode] = useState<OpenedEntity>(
    []
  );

  const [fetchedAlarmLogData, setFetchedAlarmLogData] =
    useState<AlarmModuleNodeListResponse>();
  const [fetchedAlarmLogErrors, setFetchedAlarmLogErrors] = useState<string[]>(
    []
  );
  const [fetchedAlarmLogDataInProgress, setFetchedAlarmLogDataInProgress] =
    useState(false);
  // Start Handlng Zones
  // End Handling Zones
  const fetchAlarmLogs = useCallback(async () => {
    setFetchedAlarmLogDataInProgress(true);
    setFetchedAlarmLogErrors([]);

    try {
      const params = {
        ...config.alarmLogs.params,
        standby: config.viewType !== 'alarmLog',
      };

      const resp = await API.get<AlarmModuleNodeListResponse>(
        `${apiBaseUrl}/alarm-module`,
        {params}
      );
      setFetchedAlarmLogData(resp.data);
      if (
        config.viewType === 'alarmLog' &&
        config.alarmLogs.params.status === 'all'
      ) {
        reduxActions.assets.setAssets(reduxDispatch, {
          alarm_modules: resp.data.items,
        });
      }
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedAlarmLogErrors(messages);
    } finally {
      setFetchedAlarmLogDataInProgress(false);
    }
  }, [config.viewType, config.alarmLogs.params]);

  useEffect(() => {
    fetchAlarmLogs();
  }, [config.alarmLogs.params]);

  const alarmRefreshPeriod = useConfiguration(
    'alarm',
    'alarm_autorefresh_rate'
  );
  const alarmRefreshInterval = alarmRefreshPeriod?.value
    ? getMsFromS(+alarmRefreshPeriod?.value)
    : null;
  useRefreshInterval(fetchAlarmLogs, alarmRefreshInterval);
  /*****************/
  /* opened modals */
  /*****************/

  const [openedEmployees, setOpenedEmployees] = useState<OpenedEntity>({});
  const [openedAssets, setOpenedAssets] = useState<OpenedEntity>([]);

  const [openedCommunicationNodes, setOpenedCommunicationNodes] =
    useState<OpenedEntity>({});
  const [openedWifiPoints, setOpenedWifiPoints] = useState<OpenedEntity>({});
  const [locationCoordinates, setLocationCoordinates] =
    useState<MapLatLangCoordinates>();

  const handleOpenItem = (
    id: number,
    entity: OpenedEntityType,
    mode?: OpenedEntityMode,
    active?: string
  ) => {
    if (entity === 'cn') {
      if (!(id in openedCommunicationNodes)) {
        setOpenedCommunicationNodes({
          ...openedCommunicationNodes,
          [id]: {mode, id},
        });
      }
    } else if (entity === 'wifi') {
      if (!(id in openedWifiPoints)) {
        setOpenedWifiPoints({
          ...openedWifiPoints,
          [id]: {mode, id},
        });
      }
    } else if (entity === 'employee') {
      if (!(id in openedEmployees)) {
        setOpenedEmployees({
          ...openedEmployees,
          [id]: {mode, id},
        });
      }
    } else if (entity === 'asset') {
      if (!(id in openedAssets)) {
        setOpenedAssets({
          ...openedAssets,
          [id]: {mode, id},
        });
      }
    } else if (entity === 'alarm') {
      if (!(id in openedAlarmLogNode)) {
        setOpenedAlarmLogNode({
          ...openedAlarmLogNode,
          [id]: {mode, id},
        });
      }
    } else if (entity === 'ams') {
      if (!(id in openedAMSNode)) {
        setOpenedAMSNode({
          ...openedAMSNode,
          [id]: {mode, id},
        });
      }
    } else if (entity === 'e-module') {
      if (!(id in openedAMSEModuleNode)) {
        setOpenedAMSEModuleNode({
          ...openedAMSEModuleNode,
          [id]: {mode, id},
        });
      }
    } else if (entity === 'belt') {
      if (!(id in openedBeltMonitoringNode)) {
        setOpenedBeltMonitoringNode({
          ...openedBeltMonitoringNode,
          [id]: {active, mode, id},
        });
      }
    }
  };

  /**************/
  /* group chat */
  /**************/
  const [isShownGroupChat, setIsShownGroupChat] = useState(false);

  const [panel] = usePanel();

  // 703 & 704 Alarms
  const [_703_704_alarmModule, set_703_704_alarmModule] =
    useState<EventSummaryResent>(null);
  useEffect(() => {
    if (fetchedEventSummaryData) {
      const {alarm} = fetchedEventSummaryData;
      if (
        alarm.unacked.filter((it) => it?.type === 703 || it?.type === 704)
          .length
      ) {
        set_703_704_alarmModule(
          alarm.unacked.filter(
            (it) => it?.type === 703 || it?.type === 704
          )?.[0]
        );
      }

      if (alarm.unacked.filter((it) => it?.type === 705).length) {
        const buttonPressedEvent =
          alarm.unacked.filter((it) => it?.type === 705)?.[0] ?? null;
        if (config.alarmLogs && buttonPressedEvent) {
          const v = {
            ...config.alarmLogs,
            enableAlarm: {
              open: true,
              message_id: buttonPressedEvent.id,
              commtrac_external_id:
                buttonPressedEvent?.commtrac_external_id ?? undefined,
            },
          };

          setConfig(
            update(config, {
              viewType: {
                $set: 'alarmLog',
              },
              alarmLogs: {
                $set: v ?? getAlarmLogReportData(),
              },
            })
          );
        }
      }
    }
  }, [fetchedEventSummaryData?.alarm.unacked, isSoundFilesEmpty]);

  const numberFontSize = 18;

  /*********************/
  /* broadcast message */
  /*********************/

  const [broadcastMessageMenuAnchorEl, setBroadcastMessageMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const broadcastMessageMenuOpen = Boolean(broadcastMessageMenuAnchorEl);
  const onBroadcastMessageCreated = () => {
    fetchBroadcastMessages();
    fetchCommtracNodes();
  };
  const onBroadcastMessageCleared = (item: any) => {
    fetchBroadcastMessages();
    if (selectedBroadcastMessageId === item.id) {
      setSelectedBroadcastMessageId(
        fetchedBroadcastMessagesData?.items.find(
          (i) => i.id !== selectedBroadcastMessageId
        )?.id
      );
    }

    return true;
  };

  return (
    <Box
      id="connect-view-panel"
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
      bgcolor={isDarkMode ? 'background.default' : 'grey.100'}
    >
      <DashboardPanelTitleSlot>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{marginBottom: '8px'}}
          alignItems="center"
        >
          {isCompactMode ? (
            <IconButton
              color="primary"
              onClick={() => {
                setFullTabs(!fullTabs);
              }}
              sx={{position: 'absolute', left: '8px'}}
            >
              <MenuIcon />
            </IconButton>
          ) : null}

          <AccessControl
            permissions={['post::/commtrac-broadcast-message']}
            fallback={
              <Box
                color={
                  fetchedBroadcastMessagesData?.count ? 'error.main' : 'inherit'
                }
                fontSize={numberFontSize}
              >
                {fetchedBroadcastMessagesData?.count ?? (
                  <HourglassEmptyIcon fontSize="large" />
                )}
              </Box>
            }
          >
            <BroadcastMessageUpsertButton
              component={Box}
              componentProps={{
                color: fetchedBroadcastMessagesData?.count
                  ? 'error.main'
                  : 'inherit',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                fontSize: numberFontSize,
                height: '48%',
                whiteSpace: 'nowrap',
                sx: {
                  cursor: 'pointer',
                },
              }}
              onSubmitted={onBroadcastMessageCreated}
            >
              <Button
                sx={{
                  position: 'absolute',
                  left: isCompactMode ? '54px' : '10px',
                  alignItems: 'center',
                  width: '256px',
                }}
                variant="outlined"
              >
                <WarningIcon
                  sx={{paddingRight: '4px'}}
                  color={
                    fetchedBroadcastMessagesData?.count ? 'error' : 'inherit'
                  }
                />
                Emergency Broadcast
                <SendIcon sx={{paddingLeft: '4px'}} />
              </Button>
            </BroadcastMessageUpsertButton>
          </AccessControl>

          <Box
            sx={{
              display: fetchedBroadcastMessagesData?.count ? 'flex' : 'none',
              py: '2px',
              border: '1px',
              flexDirection: 'row',
              alignItems: 'center',
              maxWidth: '100%',
              overflow: 'hidden',
              position: 'absolute',
              left: isCompactMode ? '316px' : '272px',
            }}
          >
            <AccessControl permissions={['get::/commtrac-broadcast-message']}>
              <Button
                variant={
                  fetchedBroadcastMessagesData?.count && !isDarkMode
                    ? 'contained'
                    : 'text'
                }
                endIcon={
                  fetchedBroadcastMessagesData?.count ? (
                    <ArrowDropDownIcon />
                  ) : null
                }
                disabled={!fetchedBroadcastMessagesData?.count}
                color={
                  fetchedBroadcastMessagesData?.count ? 'error' : 'inherit'
                }
                sx={{width: '100%'}}
                onClick={(event) =>
                  setBroadcastMessageMenuAnchorEl(event.currentTarget)
                }
              >
                <TruncatedText>
                  {`${fetchedBroadcastMessagesData?.count}: ${selectedBroadcastMessage?.text}`}
                </TruncatedText>
              </Button>
              <Menu
                anchorEl={broadcastMessageMenuAnchorEl}
                open={broadcastMessageMenuOpen}
                sx={{zIndex: 10000}}
                MenuListProps={{sx: {minWidth: 200}}}
                onBackdropClick={() => setBroadcastMessageMenuAnchorEl(null)}
              >
                {fetchedBroadcastMessagesData?.items.map((i) => (
                  <MenuItem
                    key={i.id}
                    selected={i.id === selectedBroadcastMessage?.id}
                    onClick={() => {
                      setSelectedBroadcastMessageId(i.id);
                      setBroadcastMessageMenuAnchorEl(null);
                    }}
                  >
                    {i.text}
                  </MenuItem>
                ))}
              </Menu>
            </AccessControl>
            <Box display="flex" flexGrow={1} justifyContent="flex-end" gap={1}>
              {selectedBroadcastMessage ? (
                <AccessControl
                  permissions={['patch::/commtrac-broadcast-message/:id/clear']}
                >
                  <BroadcastMessageClearButton
                    pk={selectedBroadcastMessage?.id}
                    component={IconButton}
                    componentProps={{
                      color: 'error',
                    }}
                    onDone={() =>
                      onBroadcastMessageCleared?.(selectedBroadcastMessage)
                    }
                  >
                    <ClearIcon />
                  </BroadcastMessageClearButton>
                </AccessControl>
              ) : null}
            </Box>
          </Box>

          <Box>{t(`panels.${panel?.code}`)}</Box>
        </Box>
      </DashboardPanelTitleSlot>
      <Box>
        <ConnectViewStats
          activeTab={config.viewType}
          commtracNodesTotal={fetchedCommtracNodesData?.total}
          commtracNodesConfig={config.commtracNodes}
          fullTabs={fullTabs}
          nodesTotal={fetchedNodesData?.total}
          nodesConfig={config.nodes}
          eventsCount={fetchedEventsData?.count}
          eventSummaryData={fetchedEventSummaryData}
          isNodesLoading={fetchedNodesInProgress}
          isGasMonitoringNodesLoading={fetchedGasMonitoringNodesInProgress}
          isEModulesNodesLoading={fetchedAMSEModuleNodesInProgress}
          isBeltMonitoringNodesLoading={fetchedBeltMonitoringNodesInProgress}
          isCommtracNodesLoading={fetchedCommtracNodesInProgress}
          isEventsLoading={fetchedEventsInProgress}
          isEventSummaryLoading={fetchedEventSummaryInProgress}
          isAlarmLogsLoading={fetchedAlarmLogDataInProgress}
          alarmLogsTotal={fetchedAlarmLogData?.total}
          alarmLogsConfig={config.alarmLogs}
          gasMonitoringConfig={config.gasMonitoring}
          eModulesConfig={config.eModules}
          beltMonitoringConfig={config.beltMonitoring}
          gasMonitoringTotal={fetchedGasMonitoringNodes?.total}
          emoduleTotal={fetchedAMSEModuleNodes?.total}
          beltMonitoringTotal={fetchedBeltMonitoringNodes?.total}
          onChangeGasMonitoringConfig={(v) => {
            setConfig(
              update(config, {
                gasMonitoring: {
                  $set: v ?? getGasMonitoringReportData(),
                },
              })
            );
          }}
          onChangeEModulesConfig={(v) => {
            setConfig(
              update(config, {
                eModules: {
                  $set: v ?? getAMSEModulesReportData(),
                },
              })
            );
          }}
          onChangeBeltMonitoringConfig={(v) => {
            setConfig(
              update(config, {
                beltMonitoring: {
                  $set: v ?? getBeltMonitoringReportData(),
                },
              })
            );
          }}
          onChangeCommtracNodesConfig={(v) =>
            setConfig(
              update(config, {
                commtracNodes: {
                  $set: v ?? getCommtracNodesReportData(),
                },
              })
            )
          }
          onChangeAlertLogConfig={(v) =>
            setConfig(
              update(config, {
                alarmLogs: {
                  $set: v ?? getAlarmLogReportData(),
                },
              })
            )
          }
          onChangeNodesConfig={(v) =>
            setConfig(
              update(config, {
                nodes: {
                  $set: v ?? getNodesReportData(),
                },
              })
            )
          }
          onChangeActiveTab={(v) => {
            if (config.viewType !== v) {
              setConfig(
                update(config, {
                  viewType: {
                    $set: v,
                  },
                })
              );
            }
          }}
          onEventSummaryFetch={() => {
            fetchEventSummary();
            fetchEvents();
          }}
        />
      </Box>

      <Box
        height="100%"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        position="relative"
        bgcolor={isDarkMode ? '#2E2E2E' : '#FFF'}
      >
        <Box>
          {fetchedEventSummaryErrors.map((error, idx) => (
            <Alert
              key={`error-es-${idx}`}
              severity="error"
              onClose={fetchEventSummary}
            >
              {error}
            </Alert>
          ))}
          {fetchedBroadcastMessagesErrors.map((error, idx) => (
            <Alert
              key={`error-b-${idx}`}
              severity="error"
              onClose={fetchBroadcastMessages}
            >
              {error}
            </Alert>
          ))}
          {fetchedCommtracNodesErrors.map((error, idx) => (
            <Alert
              key={`error-c-${idx}`}
              severity="error"
              onClose={fetchCommtracNodes}
            >
              {error}
            </Alert>
          ))}
          {fetchedNodesErrors.map((error, idx) => (
            <Alert key={`error-n-${idx}`} severity="error" onClose={fetchNodes}>
              {error}
            </Alert>
          ))}
          {fetchedGasMonitoringNodesErrors.map((error, idx) => (
            <Alert
              key={`error-g-${idx}`}
              severity="error"
              onClose={fetchGasMonitoringNodes}
            >
              {error}
            </Alert>
          ))}
          {fetchedAMSEModuleNodesErrors.map((error, idx) => (
            <Alert
              key={`error-g-${idx}`}
              severity="error"
              onClose={fetchAMSEModuleNodes}
            >
              {error}
            </Alert>
          ))}
          {fetchedBeltMonitoringNodesErrors.map((error, idx) => (
            <Alert
              key={`error-g-${idx}`}
              severity="error"
              onClose={fetchBeltMonitoringNodes}
            >
              {error}
            </Alert>
          ))}
          {fetchedEventsErrors.map((error, idx) => (
            <Alert
              key={`error-e-${idx}`}
              severity="error"
              onClose={fetchEvents}
            >
              {error}
            </Alert>
          ))}
          {fetchedAlarmLogErrors.map((error, idx) => (
            <Alert
              key={`error-es-${idx}`}
              severity="error"
              onClose={fetchEventSummary}
            >
              {error}
            </Alert>
          ))}
        </Box>
        <Box height="100%" overflow="hidden">
          {config.viewType === 'commtracNodes' ? (
            <>
              <Backdrop
                open={fetchedCommtracNodesInProgress}
                sx={{position: 'absolute', zIndex: 1199}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <CommtracNodesReport
                value={config.commtracNodes}
                onChange={(v) =>
                  setConfig(
                    update(config, {
                      commtracNodes: {
                        $set: v ?? getCommtracNodesReportData(),
                      },
                    })
                  )
                }
                loading={fetchedCommtracNodesInProgress}
                fetchedData={fetchedCommtracNodesData}
                selectedBroadcastMessage={selectedBroadcastMessage}
                onOpenHistory={props.onOpenHistory}
                onRefresh={fetchCommtracNodes}
                onOpenItem={handleOpenItem}
                onChangeLocationCoordinates={setLocationCoordinates}
                onChangeIsShownGroupChat={setIsShownGroupChat}
              />
            </>
          ) : config.viewType === 'nodes' ? (
            <>
              <Backdrop
                open={fetchedNodesInProgress}
                sx={{position: 'absolute', zIndex: 1199}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <NodesReport
                value={config.nodes}
                locationCoordinates={locationCoordinates}
                loading={fetchedNodesInProgress}
                fetchedData={fetchedNodesData}
                onOpenHistory={props.onOpenHistory}
                onRefresh={() => {
                  fetchNodes();
                  setLocationCoordinates(undefined);
                }}
                onChange={(v) =>
                  setConfig(
                    update(config, {
                      nodes: {
                        $set: v ?? getNodesReportData(),
                      },
                    })
                  )
                }
                onOpenItem={handleOpenItem}
                onChangeLocationCoordinates={setLocationCoordinates}
              />
            </>
          ) : config.viewType === 'events' ? (
            <>
              <Backdrop
                open={fetchedEventsInProgress}
                sx={{position: 'absolute', zIndex: 1199}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <EventsReport
                value={config.events}
                loading={fetchedEventsInProgress}
                fetchedData={fetchedEventsData}
                eventIds={eventIds}
                onRefresh={fetchEvents}
                onChange={(v) =>
                  setConfig(
                    update(config, {
                      events: {
                        $set: v ?? getEventsReportData(),
                      },
                    })
                  )
                }
              />
            </>
          ) : config.viewType === 'alarmLog' ? (
            <>
              <Backdrop
                open={fetchedAlarmLogDataInProgress}
                sx={{position: 'absolute', zIndex: 1199}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <AlarmLogReport
                value={config.alarmLogs}
                onChange={(v) => {
                  setConfig(
                    update(config, {
                      alarmLogs: {
                        $set: v ?? getAlarmLogReportData(),
                      },
                    })
                  );
                }}
                locationCoordinates={locationCoordinates}
                onOpenItem={handleOpenItem}
                onOpenHistory={props.onOpenHistory}
                onRefresh={fetchAlarmLogs}
                loading={fetchedAlarmLogDataInProgress}
                fetchedData={fetchedAlarmLogData}
                onChangeLocationCoordinates={setLocationCoordinates}
              />
            </>
          ) : config.viewType === 'gasMonitoring' ? (
            <>
              <Backdrop
                open={fetchedGasMonitoringNodesInProgress}
                sx={{position: 'absolute', zIndex: 1199}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <GasMonitoringReport
                value={config.gasMonitoring}
                fetchedData={fetchedGasMonitoringNodes}
                onOpenItem={handleOpenItem}
                onOpenHistory={props.onOpenHistory}
                isLoading={fetchedGasMonitoringNodesInProgress}
                locationCoordinates={locationCoordinates}
                onChangeLocationCoordinates={setLocationCoordinates}
                onRefresh={() => {
                  fetchGasMonitoringNodes();
                  //fetchAMSEModuleNodes();
                }}
                onChange={(v) =>
                  setConfig(
                    update(config, {
                      gasMonitoring: {
                        $set: v ?? getGasMonitoringReportData(),
                      },
                    })
                  )
                }
                onAcknowledgeClose={(id) => {
                  setOpenedAMSNode(update(openedAMSNode, {$unset: [id]}));
                }}
              />
            </>
          ) : config.viewType === 'eModules' ? (
            <>
              <Backdrop
                open={fetchedAMSEModuleNodesInProgress}
                sx={{position: 'absolute', zIndex: 1199}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <EModulesReport
                value={config.eModules}
                fetchedData={fetchedAMSEModuleNodes}
                onOpenItem={handleOpenItem}
                onOpenHistory={props.onOpenHistory}
                isLoading={fetchedAMSEModuleNodesInProgress}
                locationCoordinates={locationCoordinates}
                onChangeLocationCoordinates={setLocationCoordinates}
                onRefresh={fetchAMSEModuleNodes}
                onChange={(v) =>
                  setConfig(
                    update(config, {
                      eModules: {
                        $set: v ?? getAMSEModulesReportData(),
                      },
                    })
                  )
                }
              />
            </>
          ) : config.viewType === 'beltMonitoring' ? (
            <>
              <Backdrop
                open={fetchedBeltMonitoringNodesInProgress}
                sx={{position: 'absolute', zIndex: 1199}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <BeltMonitoringReport
                value={config.beltMonitoring}
                fetchedData={fetchedBeltMonitoringNodes}
                isLoading={fetchedBeltMonitoringNodesInProgress}
                onOpenItem={handleOpenItem}
                onOpenHistory={props.onOpenHistory}
                locationCoordinates={locationCoordinates}
                onChangeLocationCoordinates={setLocationCoordinates}
                onRefresh={fetchBeltMonitoringNodes}
                onAcknowledgeClose={(id) => {
                  setOpenedBeltMonitoringNode(
                    update(openedBeltMonitoringNode, {$unset: [id]})
                  );
                }}
                onChange={(v) =>
                  setConfig(
                    update(config, {
                      beltMonitoring: {
                        $set: v ?? getBeltMonitoringReportData(),
                      },
                    })
                  )
                }
              />
            </>
          ) : null}
        </Box>
      </Box>
      {values(openedEmployees).map(({id, mode}) => (
        <ModalDraggable key={`employee-${id}`} open={true}>
          <AssetHumanItemUpsert
            pk={id}
            mode={mode === 'update' || mode === 'chat' ? mode : 'view'}
            onPurged={() => {
              fetchCommtracNodes();
              setOpenedEmployees(update(openedEmployees, {$unset: [id]}));
            }}
            onOpenHistory={(item) => {
              if (item.commtrac_node_id) {
                props.onOpenHistory?.(item.commtrac_node_id, 'employee');
                setOpenedEmployees(update(openedEmployees, {$unset: [id]}));
              }
            }}
            onSubmitted={() => {
              fetchCommtracNodes();
            }}
            onClose={() => {
              setOpenedEmployees(update(openedEmployees, {$unset: [id]}));
            }}
          />
        </ModalDraggable>
      ))}

      {values(openedAssets).map(({id, mode}) => (
        <ModalDraggable key={`asset-${id}`} open={true}>
          <AssetMachineItemUpsert
            pk={id}
            mode={mode === 'update' ? mode : 'view'}
            onPurged={() => {
              fetchCommtracNodes();
              setOpenedAssets(update(openedAssets, {$unset: [id]}));
            }}
            onOpenHistory={(item) => {
              if (item.commtrac_node_id) {
                props.onOpenHistory?.(item.commtrac_node_id, 'asset');
                setOpenedAssets(update(openedAssets, {$unset: [id]}));
              }
            }}
            onSubmitted={() => {
              fetchCommtracNodes();
            }}
            onClose={() => {
              setOpenedAssets(update(openedAssets, {$unset: [id]}));
            }}
          />
        </ModalDraggable>
      ))}

      {values(openedCommunicationNodes).map(({id, mode}) => (
        <ModalDraggable key={`cn-${id}`} open={true}>
          {({isActive}) => (
            <CommunicationNodeItemUpsert
              pk={id}
              mode={mode === 'update' || mode === 'ack' ? mode : 'view'}
              isActiveModal={isActive}
              locationCoordinates={locationCoordinates}
              onPurged={() => {
                fetchNodes();
                setOpenedCommunicationNodes(
                  update(openedCommunicationNodes, {$unset: [id]})
                );
              }}
              onOpenHistory={(externalId, type) => {
                props.onOpenHistory?.(externalId, type);
                setOpenedCommunicationNodes(
                  update(openedCommunicationNodes, {$unset: [id]})
                );
              }}
              onSubmitted={() => {
                fetchNodes();
                setLocationCoordinates(undefined);
              }}
              onClose={() => {
                setOpenedCommunicationNodes(
                  update(openedCommunicationNodes, {$unset: [id]})
                );
                setLocationCoordinates(undefined);
              }}
            />
          )}
        </ModalDraggable>
      ))}

      {values(openedWifiPoints).map(({id, mode}) => (
        <ModalDraggable key={`wifi-${id}`} open={true}>
          {({isActive}) => (
            <WifiPointItemUpsert
              pk={id}
              mode={mode === 'update' || mode === 'ack' ? mode : 'view'}
              isActiveModal={isActive}
              locationCoordinates={locationCoordinates}
              onPurged={() => {
                fetchNodes();
                setOpenedWifiPoints(update(openedWifiPoints, {$unset: [id]}));
              }}
              onOpenHistory={(type) => {
                props.onOpenHistory?.(id, type);
                setOpenedWifiPoints(update(openedWifiPoints, {$unset: [id]}));
              }}
              onSubmitted={() => {
                fetchNodes();
                setLocationCoordinates(undefined);
              }}
              onClose={() => {
                setOpenedWifiPoints(update(openedWifiPoints, {$unset: [id]}));
                setLocationCoordinates(undefined);
              }}
            />
          )}
        </ModalDraggable>
      ))}

      {values(openedAlarmLogNode).map(({id, mode}) => (
        <ModalDraggable key={`alarm-${id}`} open={true}>
          {({isActive}) => (
            <AlarmItemUpsert
              pk={id}
              isActiveModal={isActive}
              mode={mode === 'chat' ? 'view' : mode}
              onPurged={() => {
                fetchCommtracNodes();
                setOpenedAlarmLogNode(
                  update(openedAlarmLogNode, {$unset: [id]})
                );
              }}
              onOpenHistory={(item, type) => {
                props.onOpenHistory?.(item.id, type);
                setOpenedAlarmLogNode(
                  update(openedAlarmLogNode, {$unset: [id]})
                );
              }}
              onSubmitted={() => {
                fetchAlarmLogs();
              }}
              onClose={() => {
                setOpenedAlarmLogNode(
                  update(openedAlarmLogNode, {$unset: [id]})
                );
              }}
              locationCoordinates={locationCoordinates}
            />
          )}
        </ModalDraggable>
      ))}

      {values(openedAMSNode).map(({id, mode}) => {
        return mode === 'ack' ? (
          <AccessControl permissions={['patch::/ams/:id(\\d+)']}>
            <AMSSensorItemAcknowledgeButton
              item={fetchedGasMonitoringNodes?.items
                .filter((it) => it.id === id)
                ?.at(0)}
              pk={id}
              opended={true}
              locationCoordinates={locationCoordinates}
              component={IconButton}
              onSubmitted={() => {
                fetchGasMonitoringNodes();
                //reduxAppDisPatch(reduxActions.assets.fetchAmsEmodules);
                //reduxAppDisPatch(reduxActions.assets.fetchGasMonitorNodes);
              }}
              onClose={() => {
                setOpenedAMSNode(update(openedAMSNode, {$unset: [id]}));
              }}
            >
              <Tooltip title="Acknowledge">
                <WarningAmberIcon color="warning" />
              </Tooltip>
            </AMSSensorItemAcknowledgeButton>
          </AccessControl>
        ) : (
          <ModalDraggable key={`ams-${id}`} open={true}>
            {({isActive}) => (
              <AMSSensorItemUpsert
                pk={id}
                isActiveModal={isActive}
                mode={mode === 'chat' ? 'view' : mode}
                onSubmitted={() => {
                  fetchGasMonitoringNodes();
                  //reduxAppDisPatch(reduxActions.assets.fetchAmsEmodules);
                }}
                onPurged={() => {
                  fetchGasMonitoringNodes();
                  setOpenedAMSNode(update(openedAMSNode, {$unset: [id]}));
                }}
                onClose={() => {
                  setOpenedAMSNode(update(openedAMSNode, {$unset: [id]}));
                }}
                onOpenHistory={props.onOpenHistory}
                locationCoordinates={locationCoordinates}
              />
            )}
          </ModalDraggable>
        );
      })}

      {values(openedAMSEModuleNode).map(({id, mode}) => (
        <ModalDraggable key={`emodule-${id}`} open={true}>
          {({isActive}) => (
            <AMSEModuleItemUpsert
              pk={id}
              isActiveModal={isActive}
              mode={mode === 'chat' ? 'view' : mode}
              onSubmitted={() => {
                fetchAMSEModuleNodes();
              }}
              onPurged={() => {
                fetchAMSEModuleNodes();
                setOpenedAMSEModuleNode(
                  update(openedAMSEModuleNode, {$unset: [id]})
                );
              }}
              onClose={() => {
                setOpenedAMSEModuleNode(
                  update(openedAMSEModuleNode, {$unset: [id]})
                );
              }}
              onOpenHistory={props.onOpenHistory}
            />
          )}
        </ModalDraggable>
      ))}

      {values(openedBeltMonitoringNode).map(({id, mode, active}) => {
        return mode === 'ack' ? (
          <BeltNodeItemAcknowledgeButton
            item={fetchedBeltMonitoringNodes?.items
              .filter((it) => it.id === id)
              ?.at(0)}
            pk={id}
            opended={true}
            locationCoordinates={locationCoordinates}
            component={IconButton}
            onSubmitted={() => {
              fetchBeltMonitoringNodes();
            }}
            onClose={() => {
              setOpenedBeltMonitoringNode(
                update(openedBeltMonitoringNode, {$unset: [id]})
              );
            }}
          >
            <Tooltip title="Acknowledge">
              <WarningAmberIcon color="warning" />
            </Tooltip>
          </BeltNodeItemAcknowledgeButton>
        ) : mode === 'active' ? (
          <BeltNodeItemActivationModal
            pk={id}
            status={active}
            open={true}
            onClose={() =>
              setOpenedBeltMonitoringNode(
                update(openedBeltMonitoringNode, {$unset: [id]})
              )
            }
            onSubmitted={fetchBeltMonitoringNodes}
          />
        ) : (
          <ModalDraggable key={`belt-${id}`} open={true}>
            <BeltNodeItemUpsert
              pk={id}
              mode={mode === 'chat' ? 'view' : mode}
              onSubmitted={() => {
                fetchBeltMonitoringNodes();
              }}
              onPurged={() => {
                fetchBeltMonitoringNodes();
                setOpenedAMSEModuleNode(
                  update(openedBeltMonitoringNode, {$unset: [id]})
                );
              }}
              onClose={() => {
                setOpenedBeltMonitoringNode(
                  update(openedBeltMonitoringNode, {$unset: [id]})
                );
              }}
              locationCoordinates={locationCoordinates}
              onOpenHistory={props.onOpenHistory}
            />
          </ModalDraggable>
        );
      })}

      <ModalDraggable open={isShownGroupChat}>
        <AssetHumanGroupChat onClose={() => setIsShownGroupChat(false)} />
      </ModalDraggable>

      <ModalDraggable
        open={isUnassignedEmployeesOpened}
        sx={{
          width: 1000,
          left: 'calc(50% - 500px)',
        }}
      >
        <AssetHumanUnassignedList
          onOpenItem={(id) => handleOpenItem(id, 'employee')}
          onClose={() => setIsUnassignedEmployeesOpened(false)}
        />
      </ModalDraggable>

      <ModalDraggable
        open={isUnassignedAssetsOpened}
        sx={{
          width: 1000,
          left: 'calc(50% - 500px)',
        }}
      >
        <AssetMachineUnassignedList
          onOpenItem={(id) => handleOpenItem(id, 'asset')}
          onClose={() => setIsUnassignedAssetsOpened(false)}
        />
      </ModalDraggable>

      {_703_704_alarmModule && (
        <ModalDraggable
          open={true}
          sx={{
            width: 400,
            left: '50%',
            top: '50%',
            transform: 'translateX(-50%) translateY(-50%) !important',
          }}
        >
          <AlarmSimpleAcknowledgePopup
            item={_703_704_alarmModule}
            onSubmitted={() => {
              set_703_704_alarmModule(null);
              fetchEventSummary();
              fetchEvents();
            }}
          />
        </ModalDraggable>
      )}
    </Box>
  );
};
